import React from "react";

const PopupAlert = ({ alertMessage, className }) => {
  return (
    <div className={`popup-alert ${className}`}>
      <div className="popup-alert__content">
        <div className="popup-alert__message">{alertMessage}</div>
      </div>
    </div>
  );
};

export default PopupAlert;
