import React, { useContext } from "react";

const Unauthorized = () => {
  return (
    <>
      <div className="unauthorized">
        <div className="unauthorized__box">
          <p className="unauthorized__title">GIRLS</p>
          <p className="unauthorized__title unauthorized__title-small">with</p>
          <p className="unauthorized__title">TAROT</p>
        </div>
        <div className="unauthorized__image">
        </div>
      </div>
    </>
  );
};

export default Unauthorized;
