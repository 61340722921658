import React from 'react';
import { Link } from "react-router-dom";

const NavMenu = () => {
  return (
    <div className="menu">
      <Link className="menu__link" to="/">
        Расклады
      </Link>
      <Link className="menu__link" to="/library">
        Библиотека
      </Link>
      <Link className="menu__link" to="/info">
        Инфо
      </Link>
    </div>
  );
}

export default NavMenu;
