import { BASE_URL } from "./constances";

function _checkResponse(resp) {
  if (!resp.ok) {
    return Promise.reject(`Error: ${resp.status}`);
  }
  return resp.json();
}

export const login = (user, userId, data, hash) => {
  return fetch(`${BASE_URL}/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: user,
      id: userId,
      data: data,
      hash: hash,
    }),
  })
    .then(_checkResponse)
    .then((data) => {
      if (data.user) {
        // localStorage.setItem("jwt", data.token);
        return data;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const saveCards = (id, data, description, question) => {
  return fetch(`${BASE_URL}/bot/save`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId: id,
      cardsData: data,
      description,
      question,
    }),
  })
    .then(_checkResponse)
    .then((data) => {
      if (data.success) {
        if (data.success) {
          return data;
        } else {
          throw new Error(data.message || "Ошибка при сохранении");
        }
      }
    })
    .catch((error) => {
      console.error("Ошибка при отправке карт:", error);
      throw error;
    });
};

export const getSubscription = (id, payload) => {
  let title = "";
  let description = "";
  let amount = "";

  switch (payload) {
      case 'monthlySubscriptionPayload':
          title = "Месячный доступ";
          description = "Доступ к приложению Girls with Tarot без ограничений на 30 дней";
          amount = "229.00";
          break;
      case 'twoWeeksSubscriptionPayload':
          title = "Двухнедельный доступ";
          description = "Доступ к приложению Girls with Tarot без ограничений на 14 дней";
          amount = "149.00";
          break;
      default:
          throw new Error("Неизвестный тип услуги.");
  }

  return fetch(`${BASE_URL}/bot/shop`, {
      method: "POST",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
          userId: id,
          title,
          description,
          payload,
          amount,
          currency: "RUB",
          email: "fourdd4@gmail.com"
      }),
  })
  .then(_checkResponse)
  .then((data) => {
      if (data.success) {
          return data;
      } else {
          throw new Error(data.message || "Ошибка при отправке инвойса");
      }
  })
  .catch((error) => {
      console.error("Ошибка при отправке запроса на получение подписки:", error);
      throw error;
  });
};

