import React from 'react';
import { useLocation } from 'react-router-dom';

const Card = ({ card, onClick }) => {
  const location = useLocation();

  return (
    <div className="card" onClick={onClick}>
      <img src={card.URL} alt={card.Name} className='card__image' />
      <p className='card__name'>{card.Name}</p>
    </div>
  );
};

export default Card;
