import fool from "../images/shut.jpg";
import magician from "../images/mag.jpg";
import priestess from "../images/verhovaya-zhrica.jpg";
import empress from "../images/imperatrica.jpg";
import emperor from "../images/imperator.jpg";
import herophant from "../images/verhovnyi-zhrec.jpg";
import lovers from "../images/vlublennye.jpg";
import chariot from "../images/kolesnica.jpg";
import strength from "../images/sila.jpg";
import hermit from "../images/otshelnik.jpg";
import fortune from "../images/koleso-fortuny.jpg";
import justice from "../images/spravedlivost.jpg";
import hungedman from "../images/poveshennyi.jpg";
import death from "../images/smert.jpg";
import temperance from "../images/umerennost.jpg";
import devil from "../images/diyavol.jpg";
import tower from "../images/padayushaya-bashnya.jpg";
import star from "../images/zvezda.jpg";
import moon from "../images/luna.jpg";
import sun from "../images/solnce.jpg";
import judgement from "../images/strashnyi-sud.jpg";
import world from "../images/mir.jpg";

import acerod from "../images/26.jpg";
import rod2 from "../images/27.jpg";
import rod3 from "../images/28.jpg";
import rod4 from "../images/29.jpg";
import rod5 from "../images/30.jpg";
import rod6 from "../images/31.jpg";
import rod7 from "../images/32.jpg";
import rod8 from "../images/33.jpg";
import rod9 from "../images/34.jpg";
import rod10 from "../images/35.jpg";
import pagerod from "../images/25.jpg";
import knightrod from "../images/24.jpg";
import queenrod from "../images/23.jpg";
import kingrod from "../images/22.jpg";

import acecup from "../images/40.jpg";
import cup2 from "../images/41.jpg";
import cup3 from "../images/42.jpg";
import cup4 from "../images/43.jpg";
import cup5 from "../images/44.jpg";
import cup6 from "../images/45.jpg";
import cup7 from "../images/46.jpg";
import cup8 from "../images/47.jpg";
import cup9 from "../images/48.jpg";
import cup10 from "../images/49.jpg";
import pagecup from "../images/39.jpg";
import knightcup from "../images/38.jpg";
import queencup from "../images/37.jpg";
import kingcup from "../images/36.jpg";

import acepentacle from "../images/54.jpg";
import pentacle2 from "../images/55.jpg";
import pentacle3 from "../images/56.jpg";
import pentacle4 from "../images/57.jpg";
import pentacle5 from "../images/58.jpg";
import pentacle6 from "../images/59.jpg";
import pentacle7 from "../images/60.jpg";
import pentacle8 from "../images/61.jpg";
import pentacle9 from "../images/62.jpg";
import pentacle10 from "../images/63.jpg";
import pagepentacle from "../images/53.jpg";
import knightpentacle from "../images/52.jpg";
import queenpentacle from "../images/51.jpg";
import kingpentacle from "../images/50.jpg";

import acesword from "../images/68.jpg";
import sword2 from "../images/69.jpg";
import sword3 from "../images/70.jpg";
import sword4 from "../images/71.jpg";
import sword5 from "../images/72.jpg";
import sword6 from "../images/73.jpg";
import sword7 from "../images/74.jpg";
import sword8 from "../images/75.jpg";
import sword9 from "../images/76.jpg";
import sword10 from "../images/77.jpg";
import pageseword from "../images/67.jpg";
import knightsword from "../images/66.jpg";
import queensword from "../images/65.jpg";
import kingsword from "../images/64.jpg";


export const BASE_URL = "https://api.webtarot.ru";

const tarotDeckData = [
  {
    id: 0,
    Name: "Шут",
    Description:
      "Карта Таро Дурак — это число 0 Старших Арканов, обозначающее количество неограниченных потенциалов. Увидеть карту Таро Дурак обычно означает, что вы находитесь на пороге неожиданного и захватывающего нового приключения. Это может потребовать от вас совершить слепой прыжок веры.",
    URL: fool,
  },
  {
    id: 1,
    Name: "Маг",
    Description:
      "Карта Таро Маг показывает, как ваши желания и желания могут быть реализованы с помощью решимости и силы воли. Когда эта карта появляется в вашем раскладе, вы можете быть уверены, что у вас есть стремление осуществить свои мечты.",
    URL: magician,
  },
  {
    id: 2,
    Name: "Жрица",
    Description:
      "Когда Верховная Жрица появляется в гадании Таро в вертикальном положении, она обычно указывает время для обучения и прислушивания к вашей интуиции, а не для того, чтобы отдавать приоритет вашему интеллекту и сознательному разуму. Она говорит вам замедлиться и подумать о том, что вы узнали, и получить еще больше знаний, прежде чем принимать решение или действовать.    ",
    URL: priestess,
  },

  {
    id: 3,
    Name: "Императрица",
    Description:
      "Вертикальная карта Таро Императрица в чтении призывает вас соединиться со своей женской стороной. Это можно перевести по-разному — подумайте о творчестве, элегантности, чувственности, плодородии и воспитании. Она говорит вам быть добрее к себе и искать красоту и счастье в своей жизни.",
    URL: empress,
  },
  {
    id: 4,
    Name: "Император",
    Description:
      "Как двойник Императрицы, Император означает мужа, который постоянен и заслуживает доверия. Он уверен в себе, контролирует свои эмоции и является примером мужской энергии. Он — отцовская фигура в жизни, которая обеспечивает структуру и безопасность, создает правила и системы и передает знания.",
    URL: emperor,
  },
  {
    id: 5,
    Name: "Жрец",
    Description:
      "Когда карта Таро Иерофант находится в прямом чтении, это означает необходимость следовать существующим соглашениям, правилам или устоявшейся процедуре. Он советует вам поддерживать условные границы, которые считаются стандартным методом. Вместо того, чтобы изобретать и нарушать нормы, вы познакомитесь с определенными традициями, верованиями и системами, которые существуют уже довольно давно. Карта Таро Иерофант воплощает в себе обычный набор духовных верований и часто ассоциируется с религией и другими формальными идеологиями. Таким образом, эта карта предполагает, что вы узнали необходимые ценности из надежного источника, такого как наставник или духовный наставник.",
    URL: herophant,
  },
  {
    id: 6,
    Name: "Влюбленные",
    Description:
      "Карта Таро Любовники в прямом положении может означать, что вам предстоит сделать важный выбор, который изменит вашу жизнь, или вы столкнетесь с дилеммой. Искушение часто является частью этого выбора или дилеммы. Вы можете чувствовать неуверенность в том, в каком направлении вам следует двигаться или кому доверять. Или вам, возможно, придется выбирать между противоречивыми и в равной степени объединяющими вариантами. Влюбленные в Таро советуют вам не идти автоматически по легкому пути (искушению).",
    URL: lovers,
  },
  {
    id: 7,
    Name: "Колесница",
    Description:
      "Когда в раскладе Таро появляется прямая карта Колесница , это говорит вам, что сейчас самое время получить то, что вы хотите. Считайте эту карту знаком поддержки. Карта Таро Колесница — это преодоление препятствий и достижение цели с помощью решимости, сосредоточенности и силы воли. Вы почувствуете мотивацию, амбициозность и контроль.",
    URL: chariot,
  },
  {
    id: 8,
    Name: "Сила",
    Description:
      "Карта Таро Сила указывает на то, что вы очень настойчивый человек и можете добиться всего, к чему стремитесь. Вы уверены в себе, и у вас нет проблем с тем, чтобы быть собой и говорить открыто. Вы привержены тому, что вам нужно сделать, и вы подходите к этому очень взвешенно и зрело. Продолжайте вести себя так, и вы добьетесь успеха во всем, чего хотите.",
    URL: strength,
  },
  {
    id: 9,
    Name: "Отшельник",
    Description:
      "В прямом положении Отшельник — неотразимая карта. Так же, как маяки света часто направляют корабли к берегу, этот мудрец открывает период личного роста и исследования. Отшельник олицетворяет мудрость, заработанную поиском высшей истины. ",
    URL: hermit,
  },
  {
    id: 10,
    Name: "Колесо Фортуны ",
    Description: "",
    URL: fortune,
  },
  {
    id: 11,
    Name: "Справедливость",
    Description: "",
    URL: justice,
  },
  {
    id: 12,
    Name: "Повешенный",
    Description: "",
    URL: hungedman,
  },
  {
    id: 13,
    Name: "Смерть",
    Description: "",
    URL: death,
  },
  {
    id: 14,
    Name: "Умеренность",
    Description: "",
    URL: temperance,
  },
  {
    id: 15,
    Name: "Дьявол",
    Description: "",
    URL: devil,
  },
  {
    id: 16,
    Name: "Башня",
    Description: "",
    URL: tower,
  },
  {
    id: 17,
    Name: "Звезда",
    Description: "",
    URL: star,
  },
  {
    id: 18,
    Name: "Луна",
    Description: "",
    URL: moon,
  },
  {
    id: 19,
    Name: "Солнце",
    Description: "",
    URL: sun,
  },
  {
    id: 20,
    Name: "Суд",
    Description: "",
    URL: judgement,
  },
  {
    id: 21,
    Name: "Мир",
    Description: "",
    URL: world,
  },
  {
    id: 22,
    Name: "Туз жезлов",
    Description: "",
    URL: acerod,
  },
  {
    id: 23,
    Name: "II Жезлов",
    Description: "",
    URL: rod2,
  },
  {
    id: 24,
    Name: "III Жезлов",
    Description: "",
    URL: rod3,
  },
  {
    id: 25,
    Name: "IV Жезлов",
    Description: "",
    URL: rod4,
  },
  {
    id: 26,
    Name: "V Жезлов",
    Description: "",
    URL: rod5,
  },
  {
    id: 27,
    Name: "VI Жезлов",
    Description: "",
    URL: rod6,
  },
  {
    id: 28,
    Name: "VII Жезлов",
    Description: "",
    URL: rod7,
  },
  {
    id: 29,
    Name: "VIII Жезлов",
    Description: "",
    URL: rod8,
  },
  {
    id: 30,
    Name: "IX Жезлов",
    Description: "",
    URL: rod9,
  },
  {
    id: 31,
    Name: "X Жезлов",
    Description: "",
    URL: rod10,
  },
  {
    id: 32,
    Name: "Паж Жезлов",
    Description: "",
    URL: pagerod,
  },
  {
    id: 33,
    Name: "Рыцарь Жезлов",
    Description: "",
    URL: knightrod,
  },
  {
    id: 34,
    Name: "Королева Жезлов",
    Description: "",
    URL: queenrod,
  },
  {
    id: 35,
    Name: "Король Жезлов",
    Description: "",
    URL: kingrod,
  },
  {
    id: 36,
    Name: "Туз Кубков",
    Description: "",
    URL: acecup,
  },
  {
    id: 37,
    Name: "II Кубков",
    Description: "",
    URL: cup2,
  },
  {
    id: 38,
    Name: "III Кубков",
    Description: "",
    URL: cup3,
  },
  {
    id: 39,
    Name: "IV Кубков",
    Description: "",
    URL: cup4,
  },
  {
    id: 40,
    Name: "V Кубков",
    Description: "",
    URL: cup5,
  },
  {
    id: 41,
    Name: "VI Кубков",
    Description: "",
    URL: cup6,
  },
  {
    id: 42,
    Name: "VII Кубков",
    Description: "",
    URL: cup7,
  },
  {
    id: 43,
    Name: "VIII Кубков",
    Description: "",
    URL: cup8,
  },
  {
    id: 44,
    Name: "IX Кубков",
    Description: "",
    URL: cup9,
  },
  {
    id: 45,
    Name: "X Кубков",
    Description: "",
    URL: cup10,
  },
  {
    id: 46,
    Name: "Паж Кубков",
    Description: "",
    URL: pagecup,
  },
  {
    id: 47,
    Name: "Рыцарь Кубков",
    Description: "",
    URL: knightcup,
  },
  {
    id: 48,
    Name: "Королева Кубков",
    Description: "",
    URL: queencup,
  },
  {
    id: 49,
    Name: "Король Кубков",
    Description: "",
    URL: kingcup,
  },
  {
    id: 50,
    Name: "Туз Пентаклей",
    Description: "",
    URL: acepentacle,
  },
  {
    id: 51,
    Name: "II Пентаклей",
    Description: "",
    URL: pentacle2,
  },
  {
    id: 52,
    Name: "III Пентаклей",
    Description: "",
    URL: pentacle3,
  },
  {
    id: 53,
    Name: "IV Пентаклей",
    Description: "",
    URL: pentacle4,
  },
  {
    id: 54,
    Name: "V Пентаклей",
    Description: "",
    URL: pentacle5,
  },
  {
    id: 55,
    Name: "VI Пентаклей",
    Description: "",
    URL: pentacle6,
  },
  {
    id: 56,
    Name: "VII Пентаклей",
    Description: "",
    URL: pentacle7,
  },
  {
    id: 57,
    Name: "VIII Пентаклей",
    Description: "",
    URL: pentacle8,
  },
  {
    id: 58,
    Name: "IX Пентаклей",
    Description: "",
    URL: pentacle9,
  },
  {
    id: 59,
    Name: "X Пентаклей",
    Description: "",
    URL: pentacle10,
  },
  {
    id: 60,
    Name: "Паж Пентаклей",
    Description: "",
    URL: pagepentacle,
  },
  {
    id: 61,
    Name: "Рыцарь Пентаклей",
    Description: "",
    URL: knightpentacle,
  },
  {
    id: 62,
    Name: "Королева Пентаклей",
    Description: "",
    URL: queenpentacle,
  },
  {
    id: 63,
    Name: "Король Пентаклей",
    Description: "",
    URL: kingpentacle,
  },
  {
    id: 64,
    Name: "Туз Мечей",
    Description: "",
    URL: acesword,
  },
  {
    id: 65,
    Name: "II Мечей",
    Description: "",
    URL: sword2,
  },
  {
    id: 66,
    Name: "III Мечей",
    Description: "",
    URL: sword3,
  },
  {
    id: 67,
    Name: "IV Мечей",
    Description: "",
    URL: sword4,
  },
  {
    id: 68,
    Name: "V Мечей",
    Description: "",
    URL: sword5,
  },
  {
    id: 69,
    Name: "VI Мечей",
    Description: "",
    URL: sword6,
  },
  {
    id: 70,
    Name: "VII Мечей",
    Description: "",
    URL: sword7,
  },
  {
    id: 71,
    Name: "VIII Мечей",
    Description: "",
    URL: sword8,
  },
  {
    id: 72,
    Name: "IX Мечей",
    Description: "",
    URL: sword9,
  },
  {
    id: 73,
    Name: "X Мечей",
    Description: "",
    URL: sword10,
  },
  {
    id: 74,
    Name: "Паж Мечей",
    Description: "",
    URL: pageseword,
  },
  {
    id: 75,
    Name: "Рыцарь Мечей",
    Description: "",
    URL: knightsword,
  },
  {
    id: 76,
    Name: "Королева Мечей",
    Description: "",
    URL: queensword,
  },
  {
    id: 77,
    Name: "Король Мечей",
    Description: "",
    URL: kingsword,
  },
];

export default tarotDeckData;
