import React from "react";
import PropTypes from "prop-types";

const PopupCard = ({ card, onClose }) => {
    if (!card) return null;

    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <h2 className="popup__title">{card.Name}</h2>
                <img src={card.URL} alt={card.Name} className="popup-image" />
                <p className="popup__description">{card.Description}</p>
                <button onClick={onClose} className="close-button">Закрыть</button>
            </div>
        </div>
    );
};

PopupCard.propTypes = {
    card: PropTypes.object, // Объект с данными карты
    onClose: PropTypes.func.isRequired, // Функция для закрытия попапа
};

export default PopupCard;
