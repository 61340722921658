import React, { useEffect, useState } from "react";
import Card from "../Card/Card.jsx";
import tarotDeckData from "../../utils/constances.js";
import NavMenu from "../NavMenu/NavMenu.jsx";
import PopupCard from "../PopupCard/PopupCard.jsx";

const Library = () => {
  const [currentCard, setCurrentCard] = useState(null);

  const handleCardClick = (card) => {
    setCurrentCard(card)
  };

  const closePopup = () => {
    setCurrentCard(null);
};

  return (
    <>
      <div className="deck">
        <h1 className="deck__title">Все карты</h1>
        <div className="cards__grid">
          {tarotDeckData.map((card, index) => (
            <div key={index} className="card-cell">
              <Card card={card} onClick={() => handleCardClick(card)} />
            </div>
          ))}
        </div>
      </div>
      {currentCard && <PopupCard card={currentCard} onClose={closePopup} />} {/* Отображаем попап */}
      <NavMenu />
    </>
  );
};

export default Library;
