import { BASE_URL } from "./constances";

class Api {
  constructor(data) {
    this._url = data.url;
  }

  _checkResponse(resp) {
    if (!resp.ok) {
      return resp.json().then((errorData) => {
        const errorMessage = resp.status;
        console.log(errorMessage);
        return Promise.reject(errorMessage);
    });
    }
    return resp.json();
  }

  getInterpretation(user, data, question) {
    const requestBody = {
      userName: user.first_name,
      userId: user.id,
      cardsData: data,
      question: question,
  };
    return fetch(`${this._url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }).then((resp) => this._checkResponse(resp));
  }
}

const GigaApi = new Api({
  url: `${BASE_URL}/neuro/interpretation`,
});

export default GigaApi;
