import React from "react";

import { useState } from "react";

const Popup = ({ onClose, className, handleBuyPremium }) => {
  const [buttonColor, setButtonColor] = useState(null);
  const [buttonBotColor, setButtonBotColor] = useState(null);

  const handleClickTop = () => {
    setButtonColor("rgba(141, 26, 5, 1)");
    setTimeout(() => {
      setButtonColor(null);
    }, 300);
  };

  const handleClickBot = () => {
    setButtonBotColor("rgba(141, 26, 5, 1)");
    setTimeout(() => {
      setButtonColor(null);
    }, 300);
  };


  const handleCloseShop = () => {
    setTimeout(() => {
      onClose();
    }, 2500);
  };

  return (
    <div className={`popup-shop ${className}`}>
      <p className="popup-shop__header">Наши предложения</p>
      <button className="popup-shop__close-button" onClick={onClose}>
      </button>
      <div className="popup-shop__content">
        <div className="popup-shop__title-box">
          <p className="popup-shop__title">Укусить яблоко</p>
          <p className="popup-shop__title popup-shop__title-red">
            149 ₽ / 14 дней
          </p>
        </div>
        <ul className="popup-shop__list">
          <li className="popup-shop__list-item">
            Неограниченное количество раскладов с трактовками в течение 14 дней
          </li>
          <li className="popup-shop__list-item">
            Сохранять результаты раскладов
          </li>
          <li className="popup-shop__list-item">
            Вы поддерживаете нас, позволяя приложению работать без навязчивой
            рекламы даже в бесплатной версии
          </li>
        </ul>
        <button
          className="popup-shop__button"
          style={{ backgroundColor: buttonColor }}
          onClick={() => {
            handleClickTop();
            handleBuyPremium("twoWeeksSubscriptionPayload");
            handleCloseShop();
          }}
        >
          Купить
        </button>
      </div>
      <div className="popup-shop__content">
        <div className="popup-shop__title-box">
          <p className="popup-shop__title">Съешь меня</p>
          <p className="popup-shop__title popup-shop__title-red">
            249 ₽ / 30 дней
          </p>
        </div>
        <ul className="popup-shop__list">
          <li className="popup-shop__list-item">
            Неограниченное количество раскладов с трактовками в течение месяца
          </li>
          <li className="popup-shop__list-item">
            Сохранять результаты раскладов
          </li>
          <li className="popup-shop__list-item">
            Вы поддерживаете нас, позволяя приложению работать без навязчивой
            рекламы даже в бесплатной версии
          </li>
        </ul>
        <button
          className="popup-shop__button"
          style={{ backgroundColor: buttonBotColor }}
          onClick={() => {
            handleClickBot();
            handleBuyPremium("monthlySubscriptionPayload");
            handleCloseShop();
          }}
        >
          Купить
        </button>
      </div>
    </div>
  );
};

export default Popup;
